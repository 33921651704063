/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import AnalogClock from "analog-clock-react";
import { Container, Box } from "@material-ui/core";
import styles from "./layout.module.scss";
import "./layout.scss";

type LayoutProps = {
  children: React.ReactNode;
};

const clockTheme = {
  width: "275px",
  border: true,
  borderColor: "#fff",
  baseColor: "#f3fcf0",
  centerColor: "#1f271b",
  handColors: {
    second: "#ee4266",
    minute: "#540d6e",
    hour: "#ffd23f",
  },
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Container>
      <Box data-testid="layout" className={styles.layout}>
        <main data-testid="main" className={styles.layout__main}>
          <Box data-testid="clock">
            <AnalogClock useCustomTime={false} {...clockTheme} />
          </Box>
          {children}
        </main>
        <footer data-testid="footer">
          © {new Date().getFullYear()},{" "}
          <a
            data-testid="footer-link"
            rel="noreferrer"
            target="_blank"
            href="https://github.com/cxspxr"
          >
            Yaroslav Kasperovych
          </a>
        </footer>
      </Box>
    </Container>
  );
  /* eslint-disable */
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
